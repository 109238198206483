import { makeStyles, Modal } from '@material-ui/core'
import { navigate } from 'gatsby'
import React from 'react'
import chirpLogo from '../../assets/images/chirpyest-text-logo.svg'
import { BREAKPOINTS, FONTS, ROUTES } from '../../constants'
import CHRButton from '../button'

interface RegisterToEarnCashbackModalProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
  brandName: string
  commission: string
}
const RegisterToEarnCashbackModal = (
  props: RegisterToEarnCashbackModalProps
) => {
  const classes = styles()
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className={classes.modal}>
        <img src={chirpLogo} alt="logo" className={classes.logo} />
        <div className={classes.modalContainer}>
          <div className={classes.modalSection}>
            <p>
              earn up to {props.commission} cashback from {props.brandName}
            </p>
            <CHRButton
              onClick={() => navigate(ROUTES.joinWithModal)}
              label="join chirpyest - it’s free"
            />
          </div>
          <div className={classes.modalSection}>
            <p>no, i want to go straight to {props.brandName} to shop</p>
            <CHRButton
              label={'shop ' + props.brandName}
              onClick={props.onContinue}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const styles = makeStyles(theme => ({
  modal: {
    maxWidth: 800,
    width: '90%',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: 20,
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 80,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      gap: 40,
    },
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 60,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      gap: 30,
    },
  },
  modalSection: {
    flex: 1,
    minWidth: 170,
    '& > p': {
      fontFamily: FONTS.Graphik.GraphikLight,
      height: 80,
    },
  },
  logo: {
    width: '136px',
    height: '35px',
  },
}))

export default RegisterToEarnCashbackModal
